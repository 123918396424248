import React from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'

import Home from '../pages/Home'
import Produto from '../pages/Produto'
import Compra from '../pages/Compra'

const Routes: React.FC = () => {
  return (
    <Switch>
      <Route path="/" exact>
        <Home />
      </Route>

      <Route path="/compra" exact>
        <Produto />
      </Route>

      <Route path="/sucesso/:method" exact>
        <Compra />
      </Route>

      <Route path="/">
        <Redirect to="/" />
      </Route>
    </Switch>
  )
}

export default Routes
