import styled, { css, keyframes } from 'styled-components'

import 'react-credit-cards/es/styles-compiled.css'

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`

export const Container = styled.div`
  background: #f4f6f8;
  min-height: 100vh;

  .banner {
    background: #e77a22;
    color: #fff;
    min-height: 70px;
    padding: 10px 20px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    text-align: center;

    p {
      font-size: 0.8rem;
      letter-spacing: -0.5px;
    }

    b {
      font-size: 1rem;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 5px;

      span {
        letter-spacing: -0.8px;
        font-weight: 500;
      }

      svg {
        margin-right: 5px;
      }
    }

    .countdown {
      color: #ffc926;
      font-weight: bold;
      margin-left: 5px;
    }
  }

  .payment_list {
    max-width: 1040px;
    margin: 0 auto;

    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 30px;
    padding: 30px 0 60px;
  }

  .resumo {
    padding: 20px;
    background: #fff;
    border-radius: 6px;

    h2 {
      font-size: 1.2rem;
      color: #333;
    }

    &_info {
      background: #f4f6f8;
      border-radius: 6px;
      padding: 20px;
      margin-top: 30px;

      p {
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 0.8rem;
        letter-spacing: -0.5px;
        font-weight: 500;

        & + p {
          margin-top: 10px;
        }

        &.total {
          font-weight: bold;
          color: #36b376;

          span {
            font-size: 1rem;
          }
        }
      }
    }

    &_product {
      display: flex;
      align-items: center;
      margin-top: 30px;

      p {
        margin-left: 10px;
        font-size: 0.9rem;

        b {
          display: block;
          font-size: 1rem;
          margin-top: 5px;
        }
      }

      img {
        width: 60px;
        height: 60px;
      }
    }
  }

  @media (max-width: 980px) {
    .payment_list {
      grid-template-columns: 1fr;
    }

    .payment_resumo {
      order: 1;
    }
    .payment_person {
      order: 2;
    }
    .payment_card {
      order: 3;
    }
  }
`

interface PaymentProps {
  active?: boolean
  completed?: boolean
  waiting?: boolean
}

export const Payment = styled.div<PaymentProps>`
  padding: 20px;
  background: #fff;
  border-radius: 6px;
  border: 2px solid ${props => (props.active ? '#725bc2' : 'transparent')};

  & + div {
    margin-top: 30px;
  }

  ${props =>
    props.waiting &&
    css`
      opacity: 0.5;

      .payment_header {
        margin-bottom: 0 !important;
      }

      form {
        display: none;
      }
    `}

  ${props =>
    props.completed &&
    css`
      background-color: #f9fdf7;

      .payment_header {
        margin-bottom: 10px !important;

        span {
          background-color: #36b376 !important;
        }

        h2 {
          color: #36b376 !important;

          svg {
            display: block !important;
          }
        }

        > p {
          display: none;
        }

        .payment_completed {
          margin-top: 10px;
          display: block !important;

          p {
            font-size: 0.9rem;
            margin-bottom: 10px;
            font-weight: bold;
            margin-top: 10px;
          }

          small {
            display: block;
            margin-top: 5px;
          }
        }
      }

      form {
        display: none;
      }
    `}

  .payment_header {
    position: relative;
    padding: 0 30px;
    margin-bottom: 40px;

    .payment_completed {
      display: none;
    }

    span {
      height: 21px;
      width: 21px;
      border-radius: 50%;
      background: #725bc2;
      color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: bold;
      font-size: 0.9rem;
      position: absolute;
      left: 0;
      top: 2px;
    }

    h2 {
      color: #725bc2;
      font-size: 1.2rem;
      display: flex;
      align-items: center;

      svg {
        display: none;
        margin-left: 10px;
      }
    }

    p {
      color: #666;
      line-height: 1.2;
      margin-top: 5px;
      font-size: 0.8rem;
    }
  }

  .form-block {
    & + .form-block {
      margin-top: 15px;
    }

    label {
      display: block;
      margin-bottom: 6px;
      font-size: 13px;
      color: #333;
      letter-spacing: -0.5px;
    }

    input,
    select {
      outline: 0;
      width: 100%;
      display: block;
      font-size: 13px;
      border-radius: 6px;
      color: #333;
      border: 1px solid #d0d0d0;
      padding: 12px 20px;
      transition: all 0.2s;

      &.error {
        border-color: #e50f38;
        background: #feecef;
      }
    }

    span {
      color: #e50f38;
      display: block;
      font-size: 11px;
      margin-top: 5px;
      font-weight: 500;
      letter-spacing: -0.5px;
    }
  }

  .address-frete {
    margin-top: 20px;
    padding-top: 20px;
    border-top: 1px solid #d0d0d0;

    > p {
      font-size: 13px;
      color: #333;
      letter-spacing: -0.5px;
    }

    > small {
      line-height: 1.2;
      font-size: 11px;
      color: #666;
    }

    div {
      padding: 20px;
      border: 1px solid #36b376;
      background-color: #f9fdf7;
      border-radius: 6px;
      margin: 10px 0;
      cursor: pointer;

      p {
        font-size: 13px;
        font-weight: bold;
      }

      small {
        font-size: 12px;
      }
    }
  }

  .payment_method {
    > div {
      margin-bottom: 10px;
      border: 1px solid #d0d0d0;
      border-radius: 5px;
      transition: all 0.2s;

      &.active {
        background: #f4f6f8;

        .payment_method__content {
          display: block !important;
        }
      }

      &:hover {
        background: #f4f6f8;
      }
    }

    &__header {
      padding: 20px;
      cursor: pointer;
      display: flex;
      align-items: center;
      font-size: 14px;

      > svg {
        font-size: 20px;
        margin-right: 10px;
      }

      span {
        display: flex;
        align-items: center;

        svg {
          margin-right: 5px;
        }
      }
    }

    &__content {
      padding: 0 20px 20px;
      display: none;

      .rccs {
        margin-bottom: 20px;
        margin-left: -24px;

        .rccs__card {
          width: 282px;
        }
      }
    }

    .boleto,
    .pix {
      p {
        font-size: 13px;
        line-height: 1.2;
        font-weight: 500;
      }

      b {
        font-size: 15px;
        color: #36b376;
        margin-top: 10px;
        display: block;
      }
    }
  }

  button {
    background: #e77a22;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    padding: 12px 20px;
    border-radius: 6px;
    cursor: pointer;
    border: 0;
    color: #fff;
    font-weight: bold;
    margin-top: 20px;
    transition: all 0.2s;

    &:hover {
      opacity: 0.8;
    }

    svg {
      margin-left: 5px;
    }
  }

  @media (max-width: 980px) {
    .payment_method {
      &__content {
        .rccs {
          margin: 10px auto 20px;

          .rccs__card {
            width: 100%;
          }
        }
      }
    }
  }
`

export const Loading = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 20px;
  color: #fff;
  font-size: 24px;
  z-index: 99;

  h2 {
    font-size: 40px;
    font-weight: bold;
    margin: 15px 0 10px;
  }

  svg {
    font-size: 35px;
    animation: ${rotate} 0.7s linear infinite;
  }
`
