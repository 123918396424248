import React, { useEffect, useState } from 'react'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { Redirect, useParams, useHistory } from 'react-router-dom'
import { AiOutlineCopy, AiOutlineQrcode } from 'react-icons/ai'
// import { BiBarcodeReader } from 'react-icons/bi'
// import ReactTooltip from 'react-tooltip'

import Header from '../../components/Header'
import qrCode from '../../assets/qrcode.png'

import { Container } from './styles'

interface URLParams {
  method: 'boleto' | 'creditcard' | 'pix'
}

const Compra: React.FC = () => {
  const history = useHistory()

  const { method } = useParams<URLParams>()

  const [email, setEmail] = useState('')

  useEffect(() => {
    const emailStorage = localStorage.getItem('email')

    if (!emailStorage) {
      history.push('')
    }

    setEmail(emailStorage as string)
  }, [history])


  if (method !== 'creditcard' && method !== 'pix') {
    return <Redirect to={`/`} />
  }

  function handleCopy() {
    console.log('aq')
  }

  return (
    <Container>
      <Header />

      <div className="content">
        <h1>Seu pedido foi realizado</h1>
        <p className="desc">
          Efetue o pagamento e você receberá um e-mail no endereço{' '}
          <b>{email}</b> com todos os detalhes de sua compra.
        </p>

        <div className="info">
          <h2>Instruções</h2>

          {/* {method === 'boleto' && (
            <div className="boleto">
              <p>1. Copie o código do boleto</p>
              <p>
                2. Pague no internet banking ou em qualquer lotérica com o
                código
              </p>
              <p>3. Aguarde enquanto é processado o boleto em até 48 horas.</p>

              <div className="input_copy">
                <label>
                  <BiBarcodeReader />
                  Código de Barras do Boleto
                </label>
                <input value={boleto} readOnly />
                <CopyToClipboard text={boleto}>
                  <button
                    type="button"
                    data-tip="custom show"
                    data-event="click"
                    data-for="copiado"
                  >
                    <AiOutlineCopy />
                    Copiar
                  </button>
                </CopyToClipboard>
              </div>
            </div>
          )} */}

          {method === 'pix' && (
            <div className="pix">
              <img src={qrCode} alt="QRCode PIX" />
              <p>1. Copie a chave copia e cole ou pague utilizando o QRCode</p>
              <p>2. Pague o valor <b>R$ {process.env.REACT_APP_VALUE}</b>.</p>
              <p>3. A confirmação é automática após o pagamento.</p>

              <div className="input_copy">
                <label>
                  <AiOutlineQrcode />
                  Chave Copia e Cole
                </label>
                <input value={process.env.REACT_APP_PIX_KEY} readOnly />
                <CopyToClipboard text={`${process.env.REACT_APP_PIX_KEY}`}>
                  <button
                    onClick={handleCopy}
                    type="button"
                  >
                    <AiOutlineCopy />
                    Copiar
                  </button>
                </CopyToClipboard>
              </div>
            </div>
          )}

          {method === 'creditcard' && (
            <div className="pix">
              <p>1. O seu pagamento entrou em análise.</p>
              <p>
                2. Basta aguardar enquanto é feito análise do seu pagamento pelo
                cartão.
              </p>
            </div>
          )}
        </div>
      </div>
    </Container>
  )
}

export default Compra
