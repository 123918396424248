import review1 from '../assets/review1.webp'
import review2 from '../assets/review2.webp'
import review3 from '../assets/review3.webp'
import review4 from '../assets/review4.jpg'
import review5 from '../assets/review5.jpg'
import review6 from '../assets/review6.jpg'
import review7 from '../assets/review7.jpg'
import review8 from '../assets/review8.jpg'
import review9 from '../assets/review9.jpg'
import review10 from '../assets/review10.jpg'
import review11 from '../assets/review11.jpg'
import review12 from '../assets/review12.webp'

const reviews = [
  {
    name: 'Bruno L.',
    date: '13/01/2021',
    img: review1,
    desc:
      'Celular muito bom! chegou a um dia e estou curtindo muito. Recomento!!'
  },
  {
    name: 'Karoline Lima de C.',
    date: '11/01/2021',
    img: review2,
    desc:
      'Chegou em 20 dias, em Campos dos Goytacazes. Produtos excelentes, comprei 2 um pra mim e um pro meu marido. Amei mesmo! Vendedores confiáveis.'
  },
  {
    name: 'Everaldo S.',
    date: '11/01/2021',
    img: review3,
    desc: 'Veio conforme o esperado, aparentemente original.'
  },
  {
    name: 'Fernanda S.',
    date: '10/01/2021',
    img: review4,
    desc:
      'Produto de ótima qualidade, e chegou antes do prazo dado, muito satisfeito, obrigado xiaomi'
  },
  {
    name: 'Roberta L.',
    date: '09/01/2021',
    img: review5,
    desc:
      'Produto original, bem embalado, chegou em 20 dias no RJ. Vou comprar mais com esse vendedor. Obrigado'
  },
  {
    name: 'M***a',
    date: '09/01/2021',
    img: review6,
    desc:
      'Muito bom, recebi em 20 dias no interior do Paraná. Exatamente como descrito.'
  },
  {
    name: 'G***o',
    date: '09/01/2021',
    img: review7,
    desc:
      'Produto de qualidade, original. envio relativamente rápido. A entrega foi bem rápido. Apesar de poder ter sido melhor embalado, chegou sem amassados'
  },
  {
    name: 'A***o',
    date: '09/01/2021',
    img: review8,
    desc:
      'ótimo produto chegou antes da data prevista. recomendo o vendedor. chegou tudo direitinho, embalado, produto de qualidade. Tudo funcionando direito. atendeu as expectativas o produto.'
  },
  {
    name: 'L***a',
    date: '08/01/2021',
    img: review9,
    desc: 'O produto é excelente e chegou em menos de 15 dias! super recomendo.'
  },
  {
    name: 'Luis R.',
    date: '11/01/2021',
    img: review10,
    desc: 'super bem embalado e chegou muito antes do prazo ESTÃO DE PARABÉNS'
  },
  {
    name: 'Paulo H.',
    date: '11/01/2021',
    img: review11,
    desc:
      'Chegou dentro de um mês e meio, bem antes do previsto! Chegou certinho, bem embalado e sem nenhum problema!'
  },
  {
    name: 'Noely R.',
    date: '11/01/2021',
    img: review12,
    desc: 'Eu amei e recomendo ,produto original, funciona perfeitamente!'
  }
]

export default reviews
