import styled from 'styled-components'

import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

import section1 from '../../assets/section01.jpg'
import section2 from '../../assets/section02.jpg'
import section3 from '../../assets/section03.jpg'
import section4 from '../../assets/section04.jpg'
import section5 from '../../assets/section05.jpg'

export const Container = styled.div`
  & > * + * {
    margin-top: 20px;
  }

  .section-1 {
    display: flex;
    background-image: url(${section1});
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top center;
    flex-direction: column;
    justify-content: center;
    height: 550px;
    color: #333;
    padding-left: 200px;

    h1 {
      font-size: 3rem;
      font-weight: 400;
      margin: 10px 0;

      b {
        font-weight: bold;
      }
    }

    p {
      font-size: 1.2rem;
    }

    button {
      background: #ff6900;
      color: #fff;
      font-size: 1.2rem;
      padding: 10px 20px;
      border: 0;
      border-radius: 6px;
      font-weight: bold;
      margin-top: 20px;
      cursor: pointer;
      transition: ease-in-out 0.3s;
      display: inline-block;
      align-self: flex-start;

      &:hover {
        transform: scale(1.1);
      }
    }
  }

  .section-2 {
    display: flex;
    background-image: url(${section2});
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top center;
    height: 500px;
    flex-direction: column;
    text-align: center;
    padding: 80px 40px;

    h1 {
      font-size: 2rem;
    }

    p {
      margin-top: 200px;
    }
  }

  .section-3 {
    display: flex;
    background-image: url(${section3});
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top center;
    color: #fff;
    flex-direction: column;
    padding: 100px 40px;

    h1 {
      font-size: 2rem;
    }

    > p {
      font-size: 0.9rem;
      line-height: 1.5;
      margin-top: 20px;
    }

    .percents {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 30px;
      margin-top: 60px;
      height: 1000px;

      p {
        font-size: 0.9rem;
        margin-top: 20px;
      }
    }
  }

  .section-4 {
    display: flex;
    background-image: url(${section4});
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top center;
    flex-direction: column;
    padding: 100px 40px 0;
    height: 1050px;

    h1 {
      font-size: 2rem;
    }

    > p {
      font-size: 1.3rem;
      line-height: 1.5;
      margin-top: 20px;
      max-width: 900px;
    }

    .info {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      gap: 30px;
      margin-top: 60px;
      padding: 0 30px;

      div {
        text-align: center;
      }

      p {
        font-size: 0.9rem;
        margin-top: 15px;
      }

      b {
        display: block;
        font-size: 1.3rem;
        margin-top: 10px;
      }
    }
  }

  .section-5 {
    display: flex;
    background-image: url(${section5});
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top center;
    flex-direction: column;
    padding: 100px 40px 0;
    color: #fff;
    height: 860px;

    h1 {
      font-size: 2rem;
      max-width: 750px;
    }

    p {
      margin-top: 30px;
      font-size: 1rem;
      line-height: 1.5;
    }
  }

  .product {
    .slick-slider {
      width: 400px;
      margin: 0 auto;
    }

    &_header {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 30px;
    }

    &_info {
      h1 {
        font-size: 1.5rem;
      }

      b {
        color: #ff6900;
        font-size: 1.5rem;
        display: block;
        margin-top: 15px;
      }

      small {
        display: block;
        margin-top: 5px;
        font-size: 0.9rem;
      }

      a {
        background: #ff6900;
        color: #fff;
        font-size: 1.2rem;
        text-decoration: none;
        padding: 10px 20px;
        border: 0;
        border-radius: 6px;
        font-weight: bold;
        margin-top: 20px;
        cursor: pointer;
        transition: ease-in-out 0.3s;
        display: inline-block;
        align-self: flex-start;

        &:hover {
          transform: scale(1.1);
        }
      }
    }

    &_color {
      width: 30px;
      height: 30px;
      border-radius: 50%;
      border: 2px solid #fff;
      background: linear-gradient(to right, #45cafd, #217ca1);
      box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.3);
      margin-top: 20px;
      cursor: pointer;
    }

    &_stars {
      margin-top: 5px;
      font-size: 1.2rem;
      display: flex;
      align-items: center;
    }

    &_tecnical {
      margin-top: 60px;

      p {
        font-size: 1.3rem;
        font-weight: bold;
        margin: 20px 0;
      }

      table {
        width: 100%;
        border-collapse: collapse;

        tr {
          border-bottom: 1px solid #ccc;
          border-top: 1px solid #ccc;

          &:nth-child(even) {
            background: rgba(0, 0, 0, 0.1);
          }
        }

        td {
          padding: 10px;

          &:first-of-type {
            min-width: 250px;
          }

          &:last-of-type {
            width: 100%;
          }
        }
      }
    }
  }

  .avaliacoes {
    &_stars {
      display: flex;
      align-items: center;
      padding-bottom: 15px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      margin-bottom: 15px;
      font-size: 1.2rem;

      span {
        margin-left: 5px;
      }
    }

    &_list {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(290px, 1fr));
      gap: 20px;
    }

    .avaliacao {
      box-shadow: 0 0 3px rgba(0, 0, 0, 0.2);
      border-radius: 6px;
      overflow: hidden;

      img {
        min-height: 350px;
        object-fit: cover;
      }

      h2 {
        font-size: 1.2rem;
      }

      small {
        color: rgba(0, 0, 0, 0.5);
      }

      > div {
        padding: 20px;
      }

      .stars {
        margin: 10px 0;
      }

      p {
        font-size: 0.9rem;
      }
    }
  }

  .footer {
    background: #000;
    color: #fff;
    padding: 30px 0;

    .container {
      display: flex;
      justify-content: center;
      flex-direction: column;
      text-align: center;
    }

    p {
      font-size: 0.9rem;

      & + p {
        margin-top: 10px;
      }
    }

    img {
      width: 350px;
      margin: 30px auto 0;
    }
  }

  @media (max-width: 768px) {
    .section-1 {
      padding: 0 20px;
    }

    .section-2 {
      background-color: #f5fafd;
      background-size: contain;
      background-position: bottom;

      p {
        margin-top: 60px;
      }
    }

    .section-4 {
      background-size: contain;
      background-position: bottom;
      height: 1200px;

      h1,
      p {
        width: 100%;
      }

      .info {
        grid-template-columns: 1fr 1fr;
        padding: 0;
      }
    }

    .section-5 {
      height: 1000px;
    }

    .product {
      .slick-slider {
        width: 300px !important;
        margin: 0 auto;
      }

      &_header {
        grid-template-columns: 1fr;
      }

      &_tecnical {
        td:first-of-type {
          min-width: auto !important;
        }
      }
    }
  }
`
