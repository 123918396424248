import React, { ChangeEvent, useRef, useState, useEffect } from 'react'
import * as Yup from 'yup'
import axios from 'axios'
import { cpf } from 'cpf-cnpj-validator'
import { BsClock } from 'react-icons/bs'
import { HiArrowRight, HiLockClosed } from 'react-icons/hi'
import { ImSpinner2 } from 'react-icons/im'
import {
  BiCircle,
  BiCheckCircle,
  BiBarcodeReader,
  BiCreditCardFront
} from 'react-icons/bi'
import { FaCheck } from 'react-icons/fa'
import { AiOutlineQrcode } from 'react-icons/ai'
import Countdown from 'react-countdown'
import { Form } from '@unform/web'
import Cards, { Focused } from 'react-credit-cards'

import redmi1 from '../../assets/redmi1.jpg'

import api from '../../services/api'

import { Container, Payment, Loading } from './styles'
import { FormHandles } from '@unform/core'
import Input from '../../components/Input'
import InputMask from '../../components/InputMask'
import Header from '../../components/Header'
import { useHistory } from 'react-router-dom'

interface PersonData {
  name: string
  email: string
  cpf: string
  telefone: string
}

interface AddressData {
  cep: string
  endereco: string
  numero: string
  bairro: string
  complemento: string
  destinario: string
}

interface CardData {
  cartao: string
  validade: string
  cvv: string
  name: string
  cpftitular: string
  parcelas: string
  senha: string
}

interface CepResponse {
  bairro: string
  localidade: string
  logradouro: string
  uf: string
  erro: boolean
}

type Methods = 'creditcard' | 'boleto' | 'pix' | ''

const Produto: React.FC = () => {
  const history = useHistory()

  const formPersonRef = useRef<FormHandles>(null)
  const formAddressRef = useRef<FormHandles>(null)
  const formCardRef = useRef<FormHandles>(null)

  const [person, setPerson] = useState<PersonData>({} as PersonData)
  const [address, setAddress] = useState<AddressData>({} as AddressData)

  const [method, setMethod] = useState<Methods>('')
  const [step, setStep] = useState(1)
  const [loading, setLoading] = useState(false)

  const [cep, setCep] = useState('')
  const [cepLoading, setCepLoading] = useState(false)
  const [endereco, setEndereco] = useState('')
  const [bairro, setBairro] = useState('')

  const [cvv, setCvv] = useState('')
  const [senha, setSenha] = useState('')
  const [validade, setValidade] = useState('')
  const [cartao_nome, setCartaoNome] = useState('')
  const [cartao, setCartao] = useState('')
  const [cpftitular, setCpftitular] = useState('')
  const [focused, setFocused] = useState<Focused>('number')

  const [service, setService] = useState('')

  async function handleSubmitPerson(data: PersonData) {
    try {
      formPersonRef.current?.setErrors({})

      const schema = Yup.object().shape({
        nome_completo: Yup.string().required('Nome é obrigatório'),
        email: Yup.string()
          .email('Email inválido')
          .required('Email é obrigatório'),
        cpf: Yup.string().test('is-cpf', 'CPF inválido', value =>
          cpf.isValid(value as string)
        ),
        telefone: Yup.string().test(
          'is-telefone',
          'Número inválido (colocou o 9?)',
          value => /\([0-9]{2}\) 9[0-9]{4}-[0-9]{4}/gm.test(value as string)
        )
      })

      await schema.validate(data, {
        abortEarly: false
      })

      // Validation passed
      setStep(2)
      setPerson(data)
    } catch (err) {
      const validationErrors: Record<string, string> = {}

      if (err instanceof Yup.ValidationError) {
        err.inner.forEach(error => {
          validationErrors[error.path as string] = error.message
        })

        formPersonRef.current?.setErrors(validationErrors)
      }
    }
  }

  async function handleSubmitAddress(data: AddressData) {
    try {
      formAddressRef.current?.setErrors({})

      const schema = Yup.object().shape({
        cep: Yup.string().test('is-cep', 'CEP inválido', value =>
          /[0-9]{5}-[0-9]{3}/gm.test(value as string)
        ),
        endereco: Yup.string().required('Endereço é obrigatório'),
        numero: Yup.string().required('Número é obrigatório'),
        bairro: Yup.string().required('Bairro é obrigatório'),
        complemento: Yup.string(),
        destinatario: Yup.string().required('Destinatário é obrigatório')
      })

      await schema.validate(data, {
        abortEarly: false
      })

      // Validation passed
      setStep(3)
      setAddress(data)
    } catch (err) {
      const validationErrors: Record<string, string> = {}

      if (err instanceof Yup.ValidationError) {
        err.inner.forEach(error => {
          validationErrors[error.path as string] = error.message
        })

        formAddressRef.current?.setErrors(validationErrors)
      }
    }
  }

  async function handleSubmitCard(data: CardData) {
    try {
      formCardRef.current?.setErrors({})

      const schema = Yup.object().shape({
        method: Yup.string().required(),
        cartao: Yup.string().when('method', {
          is: 'creditcard',
          then: Yup.string().test('is-creditcard', 'Cartão inválido', value =>
            /[0-9]{4} [0-9]{4} [0-9]{4} [0-9]{4}/gm.test(value as string)
          ),
          otherwise: Yup.string()
        }),
        cartao_nome: Yup.string().when('method', {
          is: 'creditcard',
          then: Yup.string().required('Nome e sobrenome é obrigatório'),
          otherwise: Yup.string()
        }),
        validade: Yup.string().when('method', {
          is: 'creditcard',
          then: Yup.string().test('is-validade', 'Validade inválida', value => {
            try {
              const dateSplitted = value?.split('/') as string[]
              const mes = Number(dateSplitted[0])
              const ano = Number(dateSplitted[1])

              if (mes > 12 || mes <= 0) return false

              if (ano < new Date().getFullYear() - 2000) return false

              return true
            } catch {
              return false
            }
          })
        }),
        cvv: Yup.string().when('method', {
          is: 'creditcard',
          then: Yup.string()
            .required('CVV é obrigatório')
            .min(3, 'CVV inválido')
            .max(4, 'CVV inválido'),
          otherwise: Yup.string()
        }),
        senha: Yup.string().when('method', {
          is: 'creditcard',
          then: Yup.string()
            .required('Senha é obrigatório')
            .min(4, 'Senha inválida')
            .max(6, 'Senha inválida'),
          otherwise: Yup.string()
        }),
        cpftitular: Yup.string().when('method', {
          is: 'creditcard',
          then: Yup.string().test('is-cpf', 'CPF inválido', value =>
            cpf.isValid(value as string)
          ),
          otherwise: Yup.string()
        }),
        parcelas: Yup.string()
      })

      await schema.validate(data, {
        abortEarly: false
      })

      // Validation passed
      setStep(4)
      setLoading(true)

      await api.post('/compras', {
        ...person,
        ...address,
        ...data,
        service,
        value: Number(process.env.REACT_APP_VALUE?.replace(',', '.'))
      })

      localStorage.setItem('email', person.email)
      history.push(`/sucesso/${method}`)
    } catch (err) {
      const validationErrors: Record<string, string> = {}

      if (err instanceof Yup.ValidationError) {
        err.inner.forEach(error => {
          validationErrors[error.path as string] = error.message
        })

        formCardRef.current?.setErrors(validationErrors)
      }
    }
  }

  async function getCep(event: ChangeEvent<HTMLInputElement>) {
    const value = event.target.value

    setCep(value)

    if (value.length === 9) {
      setCepLoading(true)
      try {
        formAddressRef.current?.setFieldError('cep', '')

        const { data } = await axios.get<CepResponse>(
          `https://viacep.com.br/ws/${value.replace('-', '')}/json/`
        )

        if (data.erro) {
          throw new Error()
        }

        setBairro(data.bairro)
        setEndereco(`${data.logradouro}, ${data.localidade} - ${data.uf}`)
      } catch (err) {
        setBairro('')
        setEndereco('')
        setCep('')
        formAddressRef.current?.setFieldError('cep', 'CEP inválido')
      }
      setCepLoading(false)
    }
  }

  function newMethod(value: Methods): void | boolean {
    if (value === method) {
      return false
    }

    setCvv('')
    setValidade('')
    setCartaoNome('')
    setCartao('')
    setCpftitular('')
    setMethod(value)
  }

  function getValueParcela(divisor: number): string {
    return (Number(process.env.REACT_APP_VALUE?.replace(',', '.')) / divisor)
      .toFixed(2)
      .replace('.', ',')
  }

  return (
    <Container>
      <Header />

      {loading && (
        <Loading>
          <ImSpinner2 />
          <h2>Aguarde...</h2>
          <p>Estamos processando, não feche essa tela!</p>
        </Loading>
      )}

      <div className="banner">
        <p>
          Compre agora e garanta seu desconto e que seu produto seja enviado
          rapidamente.
        </p>
        <b>
          <BsClock />
          <span>Oferta termina em</span>
          <Countdown
            date={Date.now() + 20 * 60 * 1000}
            renderer={({ hours, minutes, seconds }) => (
              <span className="countdown">
                {hours.toString().padStart(2, '0')}:
                {minutes.toString().padStart(2, '0')}:
                {seconds.toString().padStart(2, '0')}
              </span>
            )}
          />
        </b>
      </div>

      <div className="payment_list">
        <div className="payment_block payment_person">
          <Payment active={step === 1} completed={step > 1}>
            <div className="payment_header">
              <span>1</span>
              <h2>
                Dados pessoais <FaCheck />
              </h2>
              <p>
                Solicitamos apenas as informações essenciais para você fazer sua
                compra.
              </p>

              <div className="payment_completed">
                <p>{person.name}</p>
                <small>{person.email}</small>
                <small>CPF {person.cpf}</small>
              </div>
            </div>

            <Form ref={formPersonRef} onSubmit={handleSubmitPerson}>
              <div className="form-block">
                <Input
                  name="nome_completo"
                  label="Nome completo"
                  placeholder="ex: Maria de Almeida Cruz"
                />
              </div>
              <div className="form-block">
                <Input
                  type="email"
                  name="email"
                  label="E-mail"
                  placeholder="ex: maria@gmail.com"
                />
              </div>
              <div className="form-block">
                <InputMask
                  type="tel"
                  name="cpf"
                  label="CPF"
                  placeholder="000.000.000-00"
                  mask="999.999.999-99"
                  maskPlaceholder=""
                />
              </div>
              <div className="form-block">
                <InputMask
                  type="tel"
                  name="telefone"
                  label="Celular / WhatsApp"
                  placeholder="(00) 00000-0000"
                  mask="(99) 99999-9999"
                  maskPlaceholder=""
                />
              </div>
              <button type="submit">
                Continuar <HiArrowRight />
              </button>
            </Form>
          </Payment>

          <Payment active={step === 2} waiting={step < 2} completed={step > 2}>
            <div className="payment_header">
              <span>2</span>
              <h2>
                Entrega <FaCheck />
              </h2>
              <p>Cadastre um endereço</p>

              <div className="payment_completed">
                <p>Endereço para entrega:</p>
                <small>
                  {endereco}. {bairro} | CEP {cep}
                </small>
                <p>Forma de entrega:</p>
                <small>Frete grátis (6 a 35 dias úteis)</small>
              </div>
            </div>

            <Form ref={formAddressRef} onSubmit={handleSubmitAddress}>
              <div className="form-block">
                <InputMask
                  type="tel"
                  name="cep"
                  label="CEP"
                  placeholder="00000-000"
                  mask="99999-999"
                  maskPlaceholder=""
                  onChange={getCep}
                  value={cep}
                />
              </div>
              <div className="form-block">
                <Input
                  name="endereco"
                  onChange={e => setEndereco(e.target.value)}
                  value={endereco}
                  label="Endereço"
                />
              </div>
              <div className="form-block">
                <Input type="tel" name="numero" label="Número" />
              </div>
              <div className="form-block">
                <Input
                  name="bairro"
                  onChange={e => setBairro(e.target.value)}
                  value={bairro}
                  label="Bairro"
                />
              </div>
              <div className="form-block">
                <Input name="complemento" label="Complemento (opcional)" />
              </div>
              <div className="form-block">
                <Input name="destinatario" label="Destinatário" />
              </div>

              {cep.length === 9 && !cepLoading && (
                <div className="address-frete">
                  <p>Forma de entrega:</p>
                  <div>
                    <p>Frete Grátis (6 a 35 dias úteis)</p>
                    <small>Entrega garantida</small>
                  </div>
                  <small>
                    Todos os fretes tem código de rastreio e seguro ( garantia
                    de entrega ). Confira atentamente os dados, pois não nos
                    responsabilizamos por entregas não efetuadas devido a
                    endereço errado.
                  </small>
                </div>
              )}

              <button type="submit">
                Continuar <HiArrowRight />
              </button>
            </Form>
          </Payment>
        </div>

        <div className="payment_block payment_card">
          <Payment active={step === 3} waiting={step < 3} completed={step > 3}>
            <div className="payment_header">
              <span>3</span>
              <h2>
                Pagamento <FaCheck />
              </h2>
              <p>Escolha uma forma de pagamento</p>
              <div className="payment_completed">
                <p>Aguarde enquanto confirmaos o pagamento!</p>
              </div>
            </div>

            <Form ref={formCardRef} onSubmit={handleSubmitCard}>
              <Input type="hidden" name="method" value={method} />
              <div className="payment_method">
                <div
                  className={`${
                    method === 'creditcard' && 'active'
                  } creditcard`}
                >
                  <div
                    className="payment_method__header"
                    onClick={() => newMethod('creditcard')}
                  >
                    {method === 'creditcard' ? <BiCheckCircle /> : <BiCircle />}

                    <span>
                      <BiCreditCardFront /> Cartão de Crédito
                    </span>
                  </div>
                  <div className="payment_method__content">
                    <Cards
                      cvc={cvv}
                      expiry={validade}
                      focused={focused}
                      name={cartao_nome}
                      number={cartao}
                      locale={{
                        valid: 'mês/ano'
                      }}
                      placeholders={{
                        name: 'NOME SOBRENOME'
                      }}
                    />

                    <div className="form-block">
                      <InputMask
                        type="tel"
                        name="cartao"
                        label="Número do cartão"
                        placeholder="0000 0000 0000 0000"
                        mask="9999 9999 9999 9999"
                        maskPlaceholder=""
                        value={cartao}
                        onFocus={() => setFocused('number')}
                        onChange={e => setCartao(e.target.value)}
                      />
                    </div>
                    <div className="form-block">
                      <Input
                        name="cartao_nome"
                        label="Nome e sobrenome do titular"
                        placeholder="ex: Maria de Almeida Cruz"
                        onFocus={() => setFocused('name')}
                        value={cartao_nome}
                        onChange={e => setCartaoNome(e.target.value)}
                      />
                    </div>
                    <div className="form-block">
                      <InputMask
                        type="tel"
                        name="validade"
                        label="Validade (mês/ano)"
                        placeholder="mm/aa"
                        mask="99/99"
                        maskPlaceholder=""
                        value={validade}
                        onFocus={() => setFocused('expiry')}
                        onChange={e => setValidade(e.target.value)}
                      />
                    </div>
                    <div className="form-block">
                      <InputMask
                        type="tel"
                        name="cvv"
                        label="Cod. de segurança"
                        mask="9999"
                        maskPlaceholder=""
                        value={cvv}
                        onFocus={() => setFocused('cvc')}
                        onChange={e => setCvv(e.target.value)}
                      />
                    </div>
                    <div className="form-block">
                      <InputMask
                        type="tel"
                        name="senha"
                        label="Senha"
                        mask="999999"
                        maskPlaceholder=""
                        value={senha}
                        onFocus={() => setFocused('name')}
                        onChange={e => setSenha(e.target.value)}
                      />
                    </div>
                    <div className="form-block">
                      <InputMask
                        type="tel"
                        name="cpftitular"
                        label="CPF do titular"
                        placeholder="000.000.000-00"
                        mask="999.999.999-99"
                        onFocus={() => setFocused('name')}
                        maskPlaceholder=""
                        value={cpftitular}
                        onChange={e => setCpftitular(e.target.value)}
                      />
                    </div>

                    <div className="form-block">
                      <label htmlFor="parcelas">Nº de Parcelas</label>

                      <select name="parcelas">
                        <option value="1">
                          1x de R${process.env.REACT_APP_VALUE}
                        </option>
                        <option value="2">
                          2x de R${getValueParcela(2)} (sem juros)
                        </option>
                        <option value="3">
                          3x de R${getValueParcela(3)} (sem juros)
                        </option>
                        <option value="4">
                          4x de R${getValueParcela(4)} (sem juros)
                        </option>
                        <option value="5">
                          5x de R${getValueParcela(5)} (sem juros)
                        </option>
                        <option value="6">
                          6x de R${getValueParcela(6)} (sem juros)
                        </option>
                        <option value="7">
                          7x de R${getValueParcela(7)} (sem juros)
                        </option>
                        <option value="8">
                          8x de R${getValueParcela(8)} (sem juros)
                        </option>
                        <option value="9">
                          9x de R${getValueParcela(9)} (sem juros)
                        </option>
                        <option value="10">
                          10x de R${getValueParcela(10)} (sem juros)
                        </option>
                        <option value="10">
                          11x de R${getValueParcela(11)} (sem juros)
                        </option>
                        <option value="10">
                          12x de R${getValueParcela(12)} (sem juros)
                        </option>
                      </select>
                    </div>

                    <button type="submit">
                      Comprar agora <HiLockClosed />
                    </button>
                  </div>
                </div>

                {/* <div className={`${method === 'boleto' && 'active'} boleto`}>
                  <div
                    className="payment_method__header"
                    onClick={() => newMethod('boleto')}
                  >
                    {method === 'boleto' ? <BiCheckCircle /> : <BiCircle />}

                    <span>
                      <BiBarcodeReader /> Boleto bancário
                    </span>
                  </div>
                  <div className="payment_method__content">
                    <p>
                      Somente quando recebermos a confirmação, em até 48h úteis
                      após o pagamento, seguiremos com o envio das suas compras.
                      O prazo de entrega passa a ser contado somente após a
                      confirmação do pagamento.{' '}
                    </p>
                    <b>Valor no boleto: R${process.env.REACT_APP_VALUE}</b>
                    <button type="submit">
                      Comprar agora <HiLockClosed />
                    </button>
                  </div>
                </div> */}

                <div className={`${method === 'pix' && 'active'} boleto`}>
                  <div
                    className="payment_method__header"
                    onClick={() => newMethod('pix')}
                  >
                    {method === 'pix' ? <BiCheckCircle /> : <BiCircle />}
                    <span>
                      <AiOutlineQrcode /> PIX
                    </span>
                  </div>
                  <div className="payment_method__content">
                    <p>
                      Após o pagamento a confirmação é automática, seguiremos
                      com o envio das suas compras. O prazo de entrega passa a
                      ser contado somente após a confirmação do pagamento.{' '}
                    </p>
                    <b>Valor no PIX: R${process.env.REACT_APP_VALUE}</b>
                    <button type="submit">
                      Comprar agora <HiLockClosed />
                    </button>
                  </div>
                </div>
              </div>
            </Form>
          </Payment>
        </div>

        <div className="payment_block payment_resumo">
          <div className="resumo">
            <h2>Resumo</h2>
            <div className="resumo_info">
              <p>
                Produtos{' '}
                <span>
                  R$
                  {(
                    Number(process.env.REACT_APP_VALUE?.replace(',', '.')) * 1.6
                  )
                    .toFixed(2)
                    .replace('.', ',')}
                </span>
              </p>
              <p>
                Frete <span>Grátis</span>
              </p>
              <p>
                Descontos{' '}
                <span>
                  R$
                  {(
                    Number(process.env.REACT_APP_VALUE?.replace(',', '.')) -
                    Number(process.env.REACT_APP_VALUE?.replace(',', '.')) * 1.6
                  )
                    .toFixed(2)
                    .replace('.', ',')}
                </span>
              </p>
              <p className="total">
                Total <span>R${process.env.REACT_APP_VALUE}</span>
              </p>
            </div>

            <div className="resumo_product">
              <img src={redmi1} alt="Xiaomi Redmi Note 9" />
              <p>
                Xiaomi Redmi Note 9 128GB <b>R${process.env.REACT_APP_VALUE}</b>
              </p>
            </div>
          </div>
        </div>
      </div>
    </Container>
  )
}

export default Produto
