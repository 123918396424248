import React from 'react'

import { Container } from './styles'

import logo from '../../assets/logo.png'
import seguro from '../../assets/seguro.svg'

const Header: React.FC = () => {
  return (
    <Container>
      <div>
        <img src={logo} alt="Logo" className="logo" />
        <img src={seguro} alt="Seguro" className="seguro" />
      </div>
    </Container>
  )
}

export default Header
