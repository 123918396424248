import styled from 'styled-components'

export const Container = styled.div`
  background: #fff;
  height: 80px;

  > div {
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: space-between;

    max-width: 1040px;
    margin: 0 auto;
  }

  .logo {
    height: 45px;
    width: 45px;
  }

  @media (max-width: 980px) {
    > div {
      padding: 0 20px;
    }
  }
`
