import React from 'react'
import Slider from 'react-slick'
import Countdown from 'react-countdown'
import { Link } from 'react-router-dom'
import { Element, scroller } from 'react-scroll'
import { AiFillStar } from 'react-icons/ai'

import { Container } from './styles'

import porc32 from '../../assets/porc32.png'
import porc22 from '../../assets/porc22.png'
import musica from '../../assets/icon_music.png'
import navegation from '../../assets/icon_navegation.png'
import game from '../../assets/icon_game.png'
import call from '../../assets/icon_call.png'
import segura from '../../assets/segura.png'

import redmi1 from '../../assets/redmi1.jpg'
import redmi2 from '../../assets/redmi2.jpg'
import redmi3 from '../../assets/redmi3.jpg'
import redmi4 from '../../assets/redmi4.jpg'
import redmi5 from '../../assets/redmi5.jpg'
import redmi6 from '../../assets/redmi6.jpg'

import reviews from '../../services/review'

const Home: React.FC = () => {
  const goToBuy = (): void => {
    scroller.scrollTo('buy', {
      duration: 800,
      delay: 0,
      smooth: 'easeInOutQuart'
    })
  }

  return (
    <Container>
      <section className="section-1 container">
        <p>A lenda continua</p>
        <h1>
          <b>Redmi</b> Note 9
        </h1>
        <p>Câmera Quádrupla Exemplar de 48MP</p>
        <button onClick={goToBuy} type="button">
          Comprar agora
        </button>
      </section>

      <section className="section-2 container">
        <h1>A lenda continua</h1>
        <p>
          A ousada série Redmi Note proporciona a melhor experiência de topos de
          linha, dando continuidade ao legado de seus antecessores.
        </p>
      </section>

      <section className="section-3 container">
        <h1>Novo processador, desempenho poderoso</h1>
        <p>
          O Redmi Note 9 é equipado com um processador octa-core de alto
          desempenho com uma velocidade de clock máxima de 2.0GHz e uma GPU com
          frequência de 1000MHz, para um desempenho incrível e fornecer uma
          experiência perfeita em jogos.
        </p>

        <div className="percents">
          <div>
            <img src={porc32} alt="Porcentagem 32%" />
            <p>
              mais desempenho da GPU comparado à geração anterior
              <br />
              <br />
              *A geração anterior refere-se ao Redmi Note 8
            </p>
          </div>
          <div>
            <img src={porc22} alt="Porcentagem 22%" />
            <p>mais desempenho da GPU comparado à geração anterior</p>
          </div>
        </div>
      </section>

      <section className="section-4 container">
        <h1>Bateria massiva, energia duradoura</h1>
        <p>
          Com a bateria atualizada de 5020mAh (típ.), você pode desfrutar de uma
          energia duradoura. Em conjunto com o carregamento rápido de 18W, seu
          dispositivo será carregado rapidamente. * Suporte a carregamento
          reverso de 9W (Máx.)
        </p>

        <div className="info">
          <div>
            <img src={musica} alt="Musica" />
            <p>
              Reprodução de música <b>147 horas</b>
            </p>
          </div>
          <div>
            <img src={call} alt="Chamada" />
            <p>
              Chamadas <b>33 horas</b>
            </p>
          </div>
          <div>
            <img src={navegation} alt="GPS" />
            <p>
              Navegação contínua <b>147 horas</b>
            </p>
          </div>
          <div>
            <img src={game} alt="Game" />
            <p>
              Jogando <b>16 horas*</b>
            </p>
          </div>
        </div>
      </section>

      <section className="section-5 container">
        <h1>Excelente sistema de imagem, muito além da clareza</h1>
        <p>
          Com a câmera principal de 48MP você pode capturar todos os seus
          momentos favoritos e as lentes grande angular, macro e de
          profundidade, vão garantir que você obtenha os melhores resultados,
          independentemente da cena que você quer eternizar.
        </p>
      </section>

      <Element name="buy">
        <section className="product container">
          <div className="product_header">
            <Slider
              speed={500}
              slidesToScroll={1}
              slidesToShow={1}
              autoplay
              infinite
              dots
            >
              <div className="product_image">
                <img src={redmi1} alt="Xiaomi Redmi Note 9" />
              </div>
              <div className="product_image">
                <img src={redmi2} alt="Xiaomi Redmi Note 9" />
              </div>
              <div className="product_image">
                <img src={redmi3} alt="Xiaomi Redmi Note 9" />
              </div>
              <div className="product_image">
                <img src={redmi4} alt="Xiaomi Redmi Note 9" />
              </div>
              <div className="product_image">
                <img src={redmi5} alt="Xiaomi Redmi Note 9" />
              </div>
              <div className="product_image">
                <img src={redmi6} alt="Xiaomi Redmi Note 9" />
              </div>
            </Slider>
            <div className="product_info">
              <h1>Xiaomi Redmi Note 9 128gb</h1>
              <div className="product_stars">
                <AiFillStar />
                <AiFillStar />
                <AiFillStar />
                <AiFillStar />
                <AiFillStar />
                (32)
              </div>
              <div className="product_color"></div>
              <b>R$ {process.env.REACT_APP_VALUE}</b>
              <small>
                Promoção encerra em{' '}
                <Countdown
                  date={Date.now() + 20 * 60 * 1000}
                  renderer={({ hours, minutes, seconds }) => (
                    <span>
                      {hours.toString().padStart(2, '0')}:
                      {minutes.toString().padStart(2, '0')}:
                      {seconds.toString().padStart(2, '0')}
                    </span>
                  )}
                />
              </small>
              <Link to={`/compra`}>Comprar Agora</Link>
            </div>
          </div>

          <div className="product_tecnical">
            <p>Especificações</p>
            <table>
              <tr>
                <td>Marca</td>
                <td>Xiaomi</td>
              </tr>
              <tr>
                <td>Tipo de Produto</td>
                <td>Smartphone</td>
              </tr>
              <tr>
                <td>Conector de Entrada</td>
                <td>USB Tipo C</td>
              </tr>
              <tr>
                <td>CPU</td>
                <td>MediaTek® Helio G85</td>
              </tr>
              <tr>
                <td>Proteção da Tela</td>
                <td>Corning® Gorilla® Glass 5</td>
              </tr>
              <tr>
                <td>Tamanho do Display</td>
                <td>6,53&quot;</td>
              </tr>
              <tr>
                <td>Resolução do Display</td>
                <td>FHD+ (2340x1080px)</td>
              </tr>
              <tr>
                <td>Memória Interna</td>
                <td>128GB</td>
              </tr>
              <tr>
                <td>Memória RAM</td>
                <td>4GB</td>
              </tr>
              <tr>
                <td>GPU Gráfica</td>
                <td>ARM Mali G52 MC2</td>
              </tr>
              <tr>
                <td>Largura do Produto</td>
                <td>7,72 cm</td>
              </tr>
              <tr>
                <td>Altura do Produto</td>
                <td>16,23 cm</td>
              </tr>
              <tr>
                <td>Espessura do Produto</td>
                <td>0,88 cm</td>
              </tr>
              <tr>
                <td>Peso do Produto</td>
                <td>199 g</td>
              </tr>
              <tr>
                <td>Garantia</td>
                <td>12 meses</td>
              </tr>
            </table>
          </div>
        </section>
      </Element>

      <section className="avaliacoes container">
        <p className="avaliacoes_stars">
          <AiFillStar />
          <AiFillStar />
          <AiFillStar />
          <AiFillStar />
          <AiFillStar />
          <span>32 Avaliações</span>
        </p>

        <div className="avaliacoes_list">
          {reviews.map(review => (
            <div className="avaliacao" key={review.name}>
              <img src={review.img} alt="Xiaomi Redmi Note 9" />
              <div>
                <h2>{review.name}</h2>
                <small>{review.date}</small>
                <div className="stars">
                  <AiFillStar />
                  <AiFillStar />
                  <AiFillStar />
                  <AiFillStar />
                  <AiFillStar />
                </div>
                <p>{review.desc}</p>
              </div>
            </div>
          ))}
        </div>
      </section>

      <footer className="footer">
        <div className="container">
          <p>contato@xiaomibr.com</p>
          <p>® Empresa registrada CNPJ: 37.880.219/0001-32</p>
          <p>
            Rua Maurício de Nassau, 30 sala 04 - B. Glória - Belo Horizonte / MG
            - CEP: 30880-030{' '}
          </p>
          <img src={segura} alt="Segura" />
        </div>
      </footer>
    </Container>
  )
}

export default Home
