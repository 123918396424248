import styled from 'styled-components'

export const Container = styled.div`
  background: #f4f6f8;
  min-height: 100vh;

  .content {
    width: 100%;
    max-width: 1040px;
    margin: 0 auto;
    padding: 60px 20px;
  }

  .pix {
    img {
      width: 200px;
      display: block;
      margin: 0 auto 20px;
    }
  }

  h1 {
    text-align: center;
    font-size: 2rem;
    color: #36b376;
  }

  p.desc {
    margin: auto;
    text-align: center;
    max-width: 550px;
    width: 100%;
    margin-top: 20px;
    line-height: 1.5;
    letter-spacing: -0.5px;
    font-weight: 500;
  }

  .info {
    max-width: 640px;
    width: 100%;
    margin: 0 auto;
    padding: 20px;
    color: #8a6d3b;
    background: #fcf8e3;
    border: 1px solid #faebcc;
    margin-top: 60px;
    border-radius: 6px;

    h2 {
      font-size: 1.5rem;
      margin-bottom: 20px;
    }

    p {
      font-size: 0.9rem;
      letter-spacing: -0.5px;

      & + p {
        margin-top: 7px;
      }
    }

    .input_copy {
      position: relative;
      margin-top: 20px;

      label {
        color: #4d4d4d;
        display: flex;
        align-items: center;
        font-size: 12px;
        font-weight: bold;

        svg {
          margin-right: 7px;
        }
      }

      input {
        width: 100%;
        display: block;
        margin-top: 6px;
        padding: 13px 95px 13px 15px;
        font-size: 15px;
        font-weight: bold;
        color: #333;
        border: 1px dashed #999;
        border-radius: 6px;
        background: #e5e5e5;

        &:focus {
          outline: 0;
        }
      }

      button {
        position: absolute;
        bottom: 9px;
        right: 8px;
        padding: 6px 8px;
        display: flex;
        align-items: center;
        background: #666;
        color: #fff;
        border-radius: 6px;
        border: 0;
        cursor: pointer;
        font-weight: bold;

        &:focus {
          outline: 0;
        }

        svg {
          margin-right: 5px;
        }
      }
    }
  }
`
