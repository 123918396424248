import { createGlobalStyle } from 'styled-components'

export default createGlobalStyle`
  * {
    margin: 0;
    padding: 0;

    font-family: 'Montserrat', sans-serif;
    box-sizing: border-box;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  .container {
    width: 100%;
    max-width: 1270px;
    margin: 0 auto;
    padding: 40px 20px;
  }

  ul {
    list-style: none;
  }

  img {
    max-width: 100%;
  }
`
